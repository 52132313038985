.tabs {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.tab-content {
    height: 100%;
    width: 100%;
}
.tab-list {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin-top: 0;
    &--position-right {
        display: flex;
        flex-direction: row;
        padding: 0;
        margin-top: 0;
        justify-content: flex-end;
    }
}
