@import "../abstracts/mixins";
:root,
body,
ul,
h1 {
  margin: 0;
  padding: 0;
}

:root {
  font-size: 8px; // 10px = 1rem from browser default font size(16px);
  @include respond-to(xsmall) {
    font-size: 9px;
  }
  @include respond-to(small) {
    font-size: 10px;
  }

  body {
    // background-color: #555555;
    font-size: 1.6rem;
    font-family: "Nunito", sans-serif; // as existing header
  }
}

* {
  box-sizing: border-box;
}

textarea {
  border-radius: 8px;
  border: none;
}
.MuiAccordionSummary-content{
  width: 100%;
}
.ck.ck-editor__main>.ck-editor__editable{
  min-height: 200px;
}