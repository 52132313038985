@import "../../../../assets/styles/abstracts/mixins";
@import "../../../../assets/styles/abstracts/functions";
@import "../../../../assets/styles/abstracts/variables";

$primary: $color_1;
$primary-hover: darken(
  $color: $color_1,
  $amount: 5,
);
$primary-active: darken(
  $color: $color_1,
  $amount: 10,
);
$success: $success-color;
$success-hover: darken(
  $color: $success,
  $amount: 5,
);
$warning: $warning-color;
$warning-hover: darken(
  $color: $warning,
  $amount: 5,
);
$danger: $danger-color;
$danger-hover: darken(
  $color: $danger,
  $amount: 5,
);
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  span {
    margin-top: 8px;
  }
  &__loader {
    position: absolute;
    top: 8px;
    right: 3px;
    left: 3px;
    background: $color_1;
    color: #fff;
  }
  &__circle {
    margin-top: 2px;
  }
}
.button {
  position: relative;
  background-color: $primary;
  text-decoration: none;
  color: white;
  border: 1px solid $primary;
  letter-spacing: 0;
  font-weight: 500;
  border-radius: 4px;
  font-size: 1.6rem;
  font-family: "Nunito", sans-serif;
  cursor: pointer;
  span {
    font-size: 1.6rem;
  }
  @include transition(all 0.2s ease-in-out);

  &:hover {
    background-color: $primary-hover;
    .button-container__loader {
      background-color: $primary-hover;
    }
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    background-color: #e6e6e6;
    border-color: #e6e6e6;
    color: #838383;
  }

  &:focus {
    outline: none;
  }
  &:active {
    background-color: $primary-active;
  }
  &.success {
    background-color: $success;
    border-color: $success;
    &:hover {
      background-color: $success-hover;
      border-color: $success-hover;
    }
    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      pointer-events: none;
      background-color: lighten($color: $success, $amount: 30);
      border-color: lighten($color: $success, $amount: 30);
    }
  }
  @media (hover: none) {
    &.success {
      background-color: $success;
      border-color: $success;
    }
    &.primary {
      background-color: $primary !important;
      border-color: $primary !important;
    }
    &.warning {
      background-color: $warning;
      border-color: $warning;
    }
    &.danger {
      background-color: $danger;
      border-color: $danger;
    }
    &.gray {
      background-color: #dcdcdc87 !important;
      border: 2px solid #dcdcdc87 !important;
      color: #333 !important;
    }
  }
  &.primary {
    background-color: $primary;
    border-color: $primary;
    &:hover {
      background-color: $primary-hover;
      border-color: $primary-hover;
    }
    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      pointer-events: none;
      background-color: lighten($color: $primary, $amount: 30);
      border-color: lighten($color: $primary, $amount: 30);
    }
  }
  &.warning {
    background-color: $warning;
    border-color: $warning;
    &:hover {
      background-color: $warning-hover;
      border-color: $warning-hover;
    }
    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      pointer-events: none;
      background-color: lighten($color: $warning, $amount: 30);
      border-color: lighten($color: $warning, $amount: 30);
    }
  }
  &.danger {
    background-color: $danger;
    border-color: $danger;
    &:hover {
      background-color: $danger-hover;
      border-color: $danger-hover;
    }
    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      pointer-events: none;
      background-color: lighten($color: $danger, $amount: 30);
      border-color: lighten($color: $danger, $amount: 30);
    }
  }
  &.gray {
    background-color: #dcdcdc87;
    border: 2px solid #dcdcdc87;
    color: #333;
    &:hover {
      border-color: $color_1;
      background-color: $color_1;
      color: white;
    }
    &:active {
      border-color: $color_1;
      background-color: $color_1;
      color: white;
    }
    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      pointer-events: none;
      background-color: lighten($color: #79797987, $amount: 30);
      border-color: lighten($color: #79797987, $amount: 30);
    }
  }
  &.primary-inverted {
    background-color: white;
    border: 1px solid $color_5;
    color: $color_5;
    &:hover {
      border-color: $primary-hover;
    }
    &:disabled,
    &[disabled] {
      cursor: not-allowed;
      pointer-events: none;
      background-color: lighten($color: $color_5, $amount: 30);
      border-color: lighten($color: $color_5, $amount: 30);
    }
  }
  &.small {
    padding: calculateRem(16px) calculateRem(36px);
    font-size: calculateRem(16px);
    line-height: calculateRem(16px);
    &.rounded {
      width: calculateRem(64px);
      height: calculateRem(64px);
      border-radius: 50%;
      padding: 0;
      line-height: calculateRem(64px);
    }
  }
  &.medium {
    padding: calculateRem(20px) calculateRem(60px);
    font-size: calculateRem(20px);
    line-height: calculateRem(20px);
    &.rounded {
      width: calculateRem(90px);
      height: calculateRem(90px);
      font-size: calculateRem(80px);
      padding: 0;
      border-radius: 50%;
    }
  }
  &.large {
    padding: calculateRem(24px) calculateRem(40px);
    font-size: calculateRem(24px);
    line-height: calculateRem(24px);
    &.rounded {
      padding: 0;
      width: calculateRem(104px);
      height: calculateRem(104px);
      border-radius: 50%;
    }
    @include respond-to(medium) {
      padding: calculateRem(24px) calculateRem(40px);
      font-size: calculateRem(24px);
      line-height: calculateRem(24px);
      &.rounded {
        padding: 0;
        width: calculateRem(130px);
        height: calculateRem(130px);
        border-radius: 50%;
      }
    }
  }
}

.full {
  width: 100%;
  margin: 0;
  height: 5.2rem;
  display: block;
}

.stop-record {
  background: #f38831;
  color: #ff6368;
  &:hover {
    background-color: $primary-hover;
  }
}

.pulseEffect {
  box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
  // animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  border: 1px solid #d9001b;
  background: #d00000;
  transition: all 300ms ease-in-out;
  svg {
    color: #fff !important;
  }
  &:focus {
    box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
    // animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    border: 1px solid #d9001b;
    background: #d00000;
  }
  &:hover {
    animation: none;
    box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
    // animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
    border: 1px solid #d9001b;
    background: #d00000;
  }
}

/* Animation */

@-webkit-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}
