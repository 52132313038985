.block-events.e-schedule .template-wrap {
    width: 100%;
    cursor: pointer;
}

// .block-events.e-schedule .e-vertical-view .e-resource-cells {
//     height: 90px;
// }

// .block-events.e-schedule .e-timeline-view .e-resource-left-td,
// .block-events.e-schedule .e-timeline-month-view .e-resource-left-td {
//     width: 170px;
// }

// .e-schedule .e-timeline-view .e-resource-cells,
// .e-schedule .e-timeline-month-view .e-resource-cells {
//     min-height: 90px;
// }

.e-schedule .e-block-appointment {
    background-color: transparent;
}
.block-events.e-schedule .e-resource-cells.e-child-node .employee-category,
.block-events.e-schedule .e-resource-cells.e-child-node .employee-name {
    padding: 5px;

    font-size: 1.2rem;
}

.block-events.e-schedule .employee-image {
    width: 45px;
    height: 40px;
    float: left;
    border-radius: 50%;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: 45px;
}

.block-events.e-schedule .employee-name {
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.block-events.e-schedule .employee-designation {
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 5px;
    text-align: center;
    max-width: 100%;
}

.block-events.e-schedule .template-wrap .employee-image.alice {
    background-image: url("https://ej2.syncfusion.com/react/demos/src/schedule/images/alice.png");
}

.block-events.e-schedule .template-wrap .employee-image.robert {
    background-image: url("https://ej2.syncfusion.com/react/demos/src/schedule/images/robert.png");
}

.block-events.e-schedule .template-wrap .employee-image.robson {
    background-image: url("https://ej2.syncfusion.com/react/demos/src/schedule/images/robson.png");
}

.block-events.e-schedule .template-wrap .employee-image.laura {
    background-image: url("https://ej2.syncfusion.com/react/demos/src/schedule/images/laura.png");
}

.block-events.e-schedule .template-wrap .employee-image.nancy {
    background-image: url("https://ej2.syncfusion.com/react/demos/src/schedule/images/nancy.png");
}

.block-events.e-schedule .template-wrap .employee-image.margaret {
    background-image: url("https://ej2.syncfusion.com/react/demos/src/schedule/images/margaret.png");
}

.e-schedule .e-content-wrap,
.e-schedule .e-scroll-container {
    max-height: calc(100vh - 20rem);
}

.e-control,
.e-control [class^="e-"],
.e-control [class*=" e-"] {
    max-height: calc(100vh - 20rem);
}
