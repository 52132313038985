@import "../../../assets/styles/abstracts/variables";
@import "../../../assets/styles/abstracts/mixins";
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  @include respond-to(large) {
    justify-content: flex-start;
  }
  &__shade {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2;
  }
  &__video {
    object-fit: cover;
    // height: 70vh;
    height: 333px;
    z-index: 1;
    height: 100vh;
  }
  &__hero {
    // left: 0;
    // top: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    @include respond-to(large) {
      height: 100%;
      width: 100%;
      z-index: 1;
      position: relative;
      max-width: 500px;
    }
    &__image {
      width: 100%;
      height: 100%;
      @include respond-to(large) {
        // height: 100vh;
        object-fit: cover;
        min-height: 500px;
      }
    }
  }

  &__wrapper {
    z-index: 2;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 3rem 3.5rem;
    // margin: 0 2rem;
    // padding-top: 6rem;
    // height: 100%;
    // align-items: flex-start;
    min-width: 90%;
    @include respond-to(medium) {
      margin: unset;
      height: unset;
      max-width: 500px;
      min-width: 450px;
      justify-content: center;
    }
    @include respond-to(large) {
      margin: auto;
    }
    // flex: 0.4;
    // display: flex;
    // flex-direction: column;
    // margin-left: 2.9rem;
    // margin-right: 2.9rem;
    // padding-top: 12rem;
    &__user-icon {
      @include respond-to(medium) {
        margin-top: 4.2rem;
      }
      align-self: flex-start;
    }
    &__title {
      font-size: 3rem;
      width: 80%;
      margin-top: 1.6rem;
    }
    &__sub_title {
      font-size: 1.8rem;
      margin-bottom: 3.4rem;
      width: 80%;
    }
    // &__field {
    //   width: 100%;
    // }
    &__label {
      font-size: 1.6rem;
      font-family: "Nunito-SemiBold", "Nunito SemiBold", "Nunito", sans-serif;
    }
    &__error-message {
      color: red;
      text-align: left;
      align-self: flex-start;
      :last-child {
        margin-left: 0.5rem;
      }
    }
    &__submit-button {
      margin-top: 3.8rem;
      margin-bottom: 1.5rem;
      position: relative;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    &__forgot-password {
      display: flex;
      flex-direction: row;
      margin-top: 1rem;
      &__message {
        font-size: 1.5rem;
        margin-right: 0.5rem;
      }
      &__link {
        text-decoration: underline;
        cursor: pointer;
        color: inherit;
        font-size: 1.5rem;
        color: $color_1;
        text-decoration: underline;
        background-color: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        outline: 0;
      }
    }
    &__registration {
      flex-wrap: wrap;
      display: flex;
      flex-direction: row;
      margin-top: 1rem;
      margin-bottom: 3.9rem;
      padding-bottom: 2rem;
      &__message {
        font-size: 1.6rem;
        margin-right: 0.5rem;
      }
      &__link {
        text-decoration: underline;
        font-size: 1.6rem;
        cursor: pointer;
        color: $color_1;
      }
    }
  }
  &__illustration {
    height: 25rem;
    @include respond-to(medium) {
      flex: 0.6;
      height: unset;
    }
    &__image {
      height: 25rem;
      @include respond-to(medium) {
        height: unset;
        width: 100%;
      }
    }
  }
}
